import { $authHost } from '@/API'
import {
	IDtoPostByIdReport,
	IDtoPostReport,
	IGetReport,
	IReportParams
} from '@/types/getReport.types'

class GetReportService {
	getAll = (params: IReportParams) => {
		return $authHost.get<IGetReport[]>('api/check_report/', { params })
	}

	delete = (id: number) => {
		return $authHost.delete<IGetReport[]>('api/deactivate_reports/' + id)
	}

	printById = ({ id, data }: { id: number; data: IDtoPostByIdReport }) => {
		return $authHost.post('api/get_report/' + id, data)
	}

	printAll = (data: IDtoPostReport) => {
		return $authHost.post('api/get_report/', data)
	}

	downloadAll = (data: IDtoPostReport) => {
		return $authHost.post('api/get_report/' + data.route_maps[0], {
			report_type_id: data.report_type_id
		})
	}
}

export default new GetReportService()
