import { $authHost } from '@/API'
import {
	IDtoPostProductionOrderState,
	IDtoPutProductionOrderState,
	IProductionOrderState,
	IProductionOrderStateParams
} from '@/types/productionOrderState.types'

class ProductionOrderStateService {
	getAll = (params?: IProductionOrderStateParams) => {
		return $authHost.get<IProductionOrderState[]>(
			'api/production_order_state/',
			{ params }
		)
	}

	getById = ({ id }: { id: number }) => {
		return $authHost.get<IProductionOrderState>(
			'api/production_order_state/' + id
		)
	}

	create = (data: IDtoPostProductionOrderState) => {
		return $authHost.post<IProductionOrderState>(
			'api/production_order_state/',
			data
		)
	}

	update = (data: IDtoPutProductionOrderState) => {
		return $authHost.put<IProductionOrderState>(
			'api/production_order_state/' + data.id,
			data
		)
	}

	delete = ({ id }: { id: number }) => {
		return $authHost.delete<IProductionOrderState>(
			'api/production_order_state/' + id
		)
	}
}

export default new ProductionOrderStateService()
