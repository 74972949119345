import {
	QUERY_PRODUCTION_ORDER_STATE_ALL_KEY,
	QUERY_PRODUCTION_ORDER_STATE_CREATE_KEY,
	QUERY_PRODUCTION_ORDER_STATE_DELETE_KEY,
	QUERY_PRODUCTION_ORDER_STATE_KEY,
	QUERY_PRODUCTION_ORDER_STATE_PUT_KEY
} from '@/const/productionOrderState.const'
import productionOrderStateService from '@/services/productionOrderState.service'
import {
	IDtoPostProductionOrderState,
	IDtoPutProductionOrderState,
	IProductionOrderStateParams
} from '@/types/productionOrderState.types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useGetProductionOrderStateAllQuery = (
	params?: IProductionOrderStateParams
) =>
	useQuery({
		queryKey: [
			QUERY_PRODUCTION_ORDER_STATE_ALL_KEY,
			params?.depart_id,
			params?.all
		],
		queryFn: async () =>
			await productionOrderStateService.getAll(params).then(res => res.data)
	})

export const useGetProductionOrderStateQuery = (id: number) =>
	useQuery({
		queryKey: [QUERY_PRODUCTION_ORDER_STATE_KEY, id],
		queryFn: async () =>
			await productionOrderStateService.getById({ id }).then(res => res.data)
	})

export const usePostProductionOrderStateQuery = () =>
	useMutation({
		mutationKey: [QUERY_PRODUCTION_ORDER_STATE_CREATE_KEY],
		mutationFn: async (data: IDtoPostProductionOrderState) =>
			await productionOrderStateService.create(data).then(res => res.data)
	})

export const usePutProductionOrderStateQuery = (
	params?: IProductionOrderStateParams
) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_PRODUCTION_ORDER_STATE_PUT_KEY],
		onSuccess: (data, variables) => {},
		mutationFn: async (data: IDtoPutProductionOrderState) =>
			await productionOrderStateService.update(data).then(res => res.data)
	})
}

export const useDeleteProductionOrderStateQuery = () =>
	useMutation({
		mutationKey: [QUERY_PRODUCTION_ORDER_STATE_DELETE_KEY],
		onSuccess: () => {
			window.location.reload()
		},
		mutationFn: async (id: number) =>
			await productionOrderStateService.delete({ id }).then(res => res.data)
	})
