
import { $authHost } from '@/API'
import {
	IPickstateWithPagination,
	IPickstate,
	IPickstateParams,
	IDtoPostPickstate,
	IDtoPutPickstate
} from '@/types/pickstate.types'

class PickstateService {
	getAll = (params?: IPickstateParams) => {
		return $authHost.get<IPickstateWithPagination>('api/pickstate/', {
			params: params
		})
	}

	getById = ({ id }: { id: number }) => {
		return $authHost.get<IPickstate>("api/pickstate/" + id)
	}

	create = (data: IDtoPostPickstate) => {
		return $authHost.post<IPickstate>('api/pickstate/', data)
	}

	update = (data: IDtoPutPickstate) => {
		return $authHost.put<IPickstate>('api/pickstate/' + data.id, data)
	}

	delete = ({ id }: { id: number }) => {
		return $authHost.delete<IPickstate>("api/pickstate/" + id)
	}
}

export default new PickstateService()
