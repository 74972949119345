import { FC, ReactNode } from 'react'

interface IProps {
	children?: ReactNode
}

const MainContainer: FC<IProps> = ({ children }) => {
	return <div className=' bg-zinc-100'>{children}</div>
}

export default MainContainer
