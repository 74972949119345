import TableRoutemap from './components/TableRoutemap'
import ContentContainer from '@/components/Layouts/ContentContainer'
import H1Heading from '@/components/Layouts/Heading/H1Heading'
import MainContainer from '@/components/Layouts/MainContainer'
import { Button, Card } from 'antd'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

const ManualLoader: FC = () => {
	return (
		<div className='bg-zinc-100 min-h-screen'>
			<MainContainer>
				<ContentContainer>
					<H1Heading isCard isHelmet>
						Ручная загрузка
					</H1Heading>
					<Card className='mt-5'>
						<NavLink to={'/'}>
							<Button danger type='primary'>
								Выйти
							</Button>
						</NavLink>
					</Card>
					<TableRoutemap />
				</ContentContainer>
			</MainContainer>
		</div>
	)
}

export default ManualLoader
