import {
	QUERY_REPORT_TYPE_ALL_KEY,
	QUERY_REPORT_TYPE_CREATE_KEY,
	QUERY_REPORT_TYPE_DELETE_KEY,
	QUERY_REPORT_TYPE_KEY,
	QUERY_REPORT_TYPE_PUT_KEY
} from '@/const/reportType.const'
import reportTypeService from '@/services/reportType.service'
import {
	IDtoPostReportType,
	IDtoPutReportType,
	IReportTypeParams,
	IReportTypeWithPagination
} from '@/types/reportType.types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useGetReportTypeAllQuery = (params?: IReportTypeParams) =>
	useQuery({
		queryKey: [QUERY_REPORT_TYPE_ALL_KEY],
		queryFn: async () =>
			await reportTypeService.getAll(params).then(res => res.data)
	})

export const useGetReportTypeQuery = (id: number) =>
	useQuery({
		queryKey: [QUERY_REPORT_TYPE_KEY, id],
		queryFn: async () =>
			await reportTypeService.getById({ id }).then(res => res.data)
	})

export const usePostReportTypeQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_REPORT_TYPE_CREATE_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_REPORT_TYPE_ALL_KEY]
			})
		},
		mutationFn: async (data: IDtoPostReportType) =>
			await reportTypeService.create(data).then(res => res.data)
	})
}

export const usePutReportTypeQuery = (params?: IReportTypeParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_REPORT_TYPE_PUT_KEY],
		onSuccess: (data, variables) => {
			queryClient.setQueryData(
				[QUERY_REPORT_TYPE_KEY],
				(oldData: IReportTypeWithPagination) => {
					return {
						...oldData,
						data: oldData.data.map(old => {
							return old.id === data.id ? data : old
						})
					}
				}
			)
		},
		mutationFn: async (data: IDtoPutReportType) =>
			await reportTypeService.update(data).then(res => res.data)
	})
}

export const useDeleteReportTypeQuery = (id: number) =>
	useQuery({
		queryKey: [QUERY_REPORT_TYPE_DELETE_KEY],
		queryFn: async () =>
			await reportTypeService.delete({ id }).then(res => res.data)
	})
