import { Card } from 'antd'
import { FC, ReactNode } from 'react'
import { Helmet } from 'react-helmet'

interface IProps {
	children?: ReactNode
	isCard?: boolean
	isHelmet?: boolean
}

const H1Heading: FC<IProps> = ({ children, isCard, isHelmet }) => {
	const title = (
		<>
			<h2 className='text-2xl font-extrabold md:text-5xl flex gap-2 items-center'>
				{children}
			</h2>
			{isHelmet && (
				<Helmet>
					<title>{children}</title>
				</Helmet>
			)}
		</>
	)

	if (isCard) {
		return (
			<div className='pt-5'>
				<Card>{title}</Card>
			</div>
		)
	}

	return title
}

export default H1Heading
