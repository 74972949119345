import { QUERY_SEND_OPERATION_TO_MS_SQL_KEY } from '@/const/sendOperationToMsSql.const'
import sendOperationToMsSqlService from '@/services/sendOperationToMsSql.service'
import { useMutation, useQuery } from '@tanstack/react-query'

export const useSendOperationToMsSqlQuery = () =>
	useMutation({
		mutationKey: [QUERY_SEND_OPERATION_TO_MS_SQL_KEY],
		mutationFn: async (id: number) =>
			await sendOperationToMsSqlService.getById({ id }).then(res => res.data)
	})
