import {
	QUERY_ROUTE_MAP_STATUS_ALL_KEY,
	QUERY_ROUTE_MAP_STATUS_CREATE_KEY,
	QUERY_ROUTE_MAP_STATUS_DELETE_KEY,
	QUERY_ROUTE_MAP_STATUS_KEY,
	QUERY_ROUTE_MAP_STATUS_PUT_KEY
} from '@/const/routeMapStatus.const'
import routeMapStatusService from '@/services/routeMapStatus.service'
import {
	IDtoPostRouteMapStatus,
	IDtoPutRouteMapStatus,
	IRouteMapStatus,
	IRouteMapStatusParams,
	IRouteMapStatusWithPagination
} from '@/types/routeMapStatus2.types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useGetRouteMapStatusAllQuery = (params?: IRouteMapStatusParams) =>
	useQuery({
		queryKey: [QUERY_ROUTE_MAP_STATUS_ALL_KEY, params?.page],
		queryFn: async () =>
			await routeMapStatusService.getAll(params).then(res => res.data)
	})

export const useGetRouteMapStatusQuery = (id: number) =>
	useQuery({
		queryKey: [QUERY_ROUTE_MAP_STATUS_KEY, id],
		queryFn: async () =>
			await routeMapStatusService.getById({ id }).then(res => res.data)
	})

export const usePostRouteMapStatusQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_ROUTE_MAP_STATUS_CREATE_KEY],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTE_MAP_STATUS_ALL_KEY]
			})
		},
		mutationFn: async (data: IDtoPostRouteMapStatus) =>
			await routeMapStatusService.create(data).then(res => res.data)
	})
}

export const usePutRouteMapStatusQuery = (params?: IRouteMapStatusParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_ROUTE_MAP_STATUS_PUT_KEY],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTE_MAP_STATUS_ALL_KEY]
			})
		},
		mutationFn: async (data: IDtoPutRouteMapStatus) =>
			await routeMapStatusService.update(data).then(res => res.data)
	})
}

export const useDeleteRouteMapStatusQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_ROUTE_MAP_STATUS_DELETE_KEY],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTE_MAP_STATUS_ALL_KEY]
			})
		},
		mutationFn: async (id: number) =>
			await routeMapStatusService.delete({ id }).then(res => res.data)
	})
}
