import { $authHost } from '@/API'
import {
	IDtoPostReportType,
	IDtoPutReportType,
	IReportType,
	IReportTypeParams,
	IReportTypeWithPagination
} from '@/types/reportType.types'

class ReportTypeService {
	getAll = (params?: IReportTypeParams) => {
		return $authHost.get<IReportTypeWithPagination>('api/report_type/', {
			params: params
		})
	}

	getById = ({ id }: { id: number }) => {
		return $authHost.get<IReportType>('api/report_type/' + id)
	}

	create = (data: IDtoPostReportType) => {
		return $authHost.post<IReportType>('api/report_type/', data)
	}

	update = (data: IDtoPutReportType) => {
		return $authHost.put<IReportType>('api/report_type/' + data.id, data)
	}

	delete = ({ id }: { id: number }) => {
		return $authHost.delete<IReportType>('api/report_type/' + id)
	}
}

export default new ReportTypeService()
