import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'

const HoverSwitch: FC<{
	defualt: React.ReactNode
	hover: React.ReactNode
}> = ({ defualt, hover }) => {
	const [isHovered, setIsHovered] = useState(false)
	const [isBlock, setIsBlock] = useState(false)
	return (
		<div
			onClick={() => setIsBlock(true)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => {
				if (isBlock) {
					setIsBlock(false)
					return
				}
				setIsHovered(false)
			}}
		>
			{isHovered ? hover : defualt}{' '}
		</div>
	)
}

export default observer(HoverSwitch)
