import { useGetPickstateAllQuery } from '@/hooks/pickstate.hook'
import { usePutRouteOperationQuery } from '@/hooks/routeOperation.hook'
import { PlayCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { FC } from 'react'
import { FaRegStopCircle } from 'react-icons/fa'

interface IItem {
	id: number
	pickstate?: number | null
}

interface IProps {
	disable?: boolean
	className?: string
	size?: SizeType
	item: IItem
	onChange?: () => void
}

const OperationBtn: FC<IProps> = ({
	item,
	className,
	size = 'middle',
	onChange,
	disable = false
}) => {
	const { data: pickstateData } = useGetPickstateAllQuery()
	const { mutate, isPending } = usePutRouteOperationQuery()

	const not_started_state = pickstateData?.data.find(e => e.not_started_state) //5
	const started_state = pickstateData?.data.find(e => e.started_state) //6
	const done_state = pickstateData?.data.find(e => e.done_state) //8
	const canceled_state = pickstateData?.data.find(e => e.cancelled_state) //7

	return (
		<>
			{item.pickstate !== not_started_state?.id && (
				<Tooltip title='Сбросить статус операции'>
					<Button
						size={size}
						loading={isPending}
						icon={<ReloadOutlined />}
						className={`${className && className}`}
						onClick={async () => {
							if (not_started_state?.id) {
								await mutate({
									id: item.id,
									pickstate: not_started_state?.id,
									pickstate_id: not_started_state?.id
								})
							}
						}}
					/>
				</Tooltip>
			)}

			{item.pickstate === not_started_state?.id && (
				<Button
					size={size}
					disabled={disable}
					type='primary'
					loading={isPending}
					icon={<PlayCircleOutlined />}
					className={`${className && className}`}
					onClick={async () => {
						const date = new Date().toISOString()

						await mutate({
							id: item.id,
							manually: true,
							start: true
							// begin_time_fact: date
						})
						if (onChange) {
							onChange()
						}
					}}
				/>
			)}

			{item.pickstate === started_state?.id && (
				<Button
					size={size}
					disabled={disable}
					type='primary'
					loading={isPending}
					icon={<FaRegStopCircle />}
					className={`${className && className}`}
					onClick={async () => {
						const date = new Date().toISOString()
						await mutate({
							id: item.id,
							finish: true,
							count_expend: true
							// end_time_fact: date
						})
						if (onChange) {
							onChange()
						}
					}}
				/>
			)}
			{/* {item.pickstate === done_state?.id && (
				<Button
					className={`${className && className}`}
					disabled
					type='primary'
					icon={<CheckOutlined />}
				>
					Завершено
				</Button>
			)}
			{item.pickstate === canceled_state?.id && (
				<Button
					className={`${className && className}`}
					danger
					disabled
					type='primary'
					icon={<MdErrorOutline />}
				>
					Отменено
				</Button>
			)} */}
		</>
	)
}

export default OperationBtn
