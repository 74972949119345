import { useGetTableRoutemap } from '../hook/useGetTableRoutemap'
import { useDownloadRoutemap } from '@/hooks/getReport.hook'
import { useGetReportTypeAllQuery } from '@/hooks/reportType.hook'
import { useGetRoutemapAllQuery } from '@/hooks/routemap.hook'
import { Button, Card, Modal, Table, message } from 'antd'
import { FC, useEffect, useState } from 'react'

const TableRoutemap: FC = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [selectedRouteMap, setSelectedRouteMap] = useState<number[]>([])
	const { data: reportTypeAll } = useGetReportTypeAllQuery()
	const {
		mutate: mutateDownload,
		isPending: isPendingDownload,
		isError: isErrorDownload,
		isSuccess: isSuccessDownload,
		error: errorDownload
	} = useDownloadRoutemap()

	useEffect(() => {
		if (isSuccessDownload) {
			message.destroy()
			message.success('Успешно! Загрузка началась')
		}
	}, [isSuccessDownload])

	const [page, setPage] = useState(1)
	const { data, isLoading } = useGetRoutemapAllQuery({
		manual_load: true
	})
	const columns = useGetTableRoutemap(setIsOpen, setSelectedRouteMap)
	return (
		<>
			<Card className='mt-5 overflow-x-auto'>
				<Table
					className='mt-5'
					loading={isLoading}
					dataSource={data?.route_maps.map(rp => ({ ...rp, key: rp.id }))}
					pagination={{
						pageSize: data?.page_count,
						current: page,
						onChange(page) {
							setPage(page)
						},
						total: data?.total,
						hideOnSinglePage: true
					}}
					bordered
					columns={columns}
				/>
			</Card>

			<Modal
				okButtonProps={{ style: { display: 'none' } }}
				open={isOpen}
				onCancel={() => setIsOpen(false)}
			>
				<p className='text-2xl mb-3'>Выбрать форму для скачивания</p>
				{reportTypeAll?.data.map(reportType => (
					<Button
						onClick={() => {
							mutateDownload({
								report_type_id: reportType.id,
								route_maps: selectedRouteMap
							})
							setIsOpen(false)
						}}
						type='primary'
						className='my-2 w-full font-bold'
						key={reportType.id}
					>
						{reportType.description}
					</Button>
				))}
			</Modal>
		</>
	)
}

export default TableRoutemap
