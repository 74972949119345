import HoverSwitch from '@/components/HoverSwitch'
import { useGetPickstateAllQuery } from '@/hooks/pickstate.hook'
import { useSendOperationToMsSqlQuery } from '@/hooks/sendOperationToMsSql.hook'
import OperationBtn from '@/pages/ProductionOrderPage/components/OperationBtn'
import SelectWCTable from '@/pages/ProductionOrderPage/components/SelectWCTable'
import { IRouteOperation } from '@/types/RouteOperation.types'
import { dateFormat } from '@/utils/dateFormat.utils'
import { insertSpaces } from '@/utils/insertSpaces'
import {
	Button,
	Checkbox,
	DatePicker,
	TableColumnsType,
	TimePicker,
	Tooltip
} from 'antd'
import { useState } from 'react'

export const useGetTableOperationRoutemap = ({
	data
}: {
	data?: IRouteOperation[]
}): TableColumnsType<IRouteOperation> => {
	const [inAllGroup, setInAllGroup] = useState(false)

	const toMSSQLQuery = useSendOperationToMsSqlQuery()

	const { data: pickstateData } = useGetPickstateAllQuery()

	const done_state = pickstateData?.data.find(e => e.done_state) //8

	return [
		{
			title: 'Операция',
			dataIndex: 'operation',
			key: 'Операция',
			children: [
				{
					title: '№ Текущ.',
					dataIndex: 'number_technology_operation',
					key: '№',
					sorter: (a, b) =>
						Number(b?.number_operation) - Number(a?.number_operation)
				},
				{
					title: '№ След.',
					dataIndex: 'number_next_technology_operation',
					key: 'Следующий номер операции',
					sorter: (a, b) =>
						Number(b?.number_next_operation) - Number(a?.number_next_operation)
				},
				{
					title: 'Наименование',
					dataIndex: 'tech_operation_name',
					key: 'Операция',
					render: (text, item) => {
						return <p className='min-w-[250px]'>{item.tech_operation_name}</p>
					}
				},
				{
					title: 'Действие',
					dataIndex: 'Действие',
					key: 'Действие',
					align: 'center',
					render(value, record, index) {
						let disable = false
						const currentPickState = pickstateData?.data.find(
							e => e.id === record.pickstate_id?.id
						)
						if (data) {
							const sorted = data?.sort(
								(a, b) =>
									Number(a?.number_technology_operation) -
									Number(b?.number_technology_operation)
							)
							const currentIdx = sorted?.findIndex(
								e => e.pickstate_id?.id !== done_state?.id
							)
							if (sorted[currentIdx]?.id !== record.id) {
								if (currentPickState?.started_state) {
									disable = false
								} else {
									disable = true
								}
							}
						}
						return (
							<div className='flex min-w-[150px] gap-2 items-center'>
								<OperationBtn
									size='large'
									disable={disable}
									item={{ id: record.id, pickstate: record.pickstate_id?.id }}
									onChange={() => {
										// update
									}}
								/>
								<p className='font-bold text-xl'>
									{insertSpaces(currentPickState?.pickstatename || '')}
								</p>
							</div>
						)
					}
				}
			]
		},
		{
			title: 'Время',
			dataIndex: 'time_basic',
			key: 'Время',
			children: [
				{
					title: 'Норматив, сек',
					dataIndex: 'time_basic',
					key: 'Норматив времени, сек',
					sorter: (a, b) => Number(b?.time_basic) - Number(a?.time_basic),
					width: '40px'
				},
				{
					title: 'Выполнение, мин',
					dataIndex: 'time_min',
					key: 'Время выполнения, мин',
					width: '40px'
				}
			]
		},
		{
			title: 'Начало',
			dataIndex: 'Начало',
			key: 'Начало',
			children: [
				{
					title: 'Плановое',
					dataIndex: 'begin_time',
					key: 'Фактическое время выполнения операции',
					className: 'w-[145px]',
					render(value, record, index) {
						const date = dateFormat(record.begin_time)
						return (
							<HoverSwitch
								hover={
									<DatePicker className='w-[175px]' showTime value={date} />
								}
								defualt={<TimePicker className='w-[175px]' value={date} />}
							/>
						)
					}
				},
				{
					title: 'Фактическое',
					dataIndex: 'begin_time_fact',
					key: 'Плановое время начала',
					className: 'w-[145px]',
					render(value, record, index) {
						const date = dateFormat(record.begin_time_fact)
						return (
							<HoverSwitch
								hover={
									<DatePicker className='w-[175px]' showTime value={date} />
								}
								defualt={<TimePicker className='w-[175px]' value={date} />}
							/>
						)
					}
				}
			]
		},
		{
			title: 'Окончание',
			dataIndex: 'Окончание',
			key: 'Окончание',
			children: [
				{
					title: 'Плановое',
					dataIndex: 'end_time',
					key: 'Окончание',
					className: 'w-[175px]',
					render(value, record, index) {
						const date = dateFormat(record.end_time)
						return (
							<HoverSwitch
								hover={
									<DatePicker className='w-[175px]' showTime value={date} />
								}
								defualt={<TimePicker className='w-[175px]' value={date} />}
							/>
						)
					}
				},
				{
					title: 'Фактическое',
					dataIndex: 'end_time_fact',
					key: 'Окончание',
					className: 'w-[175px]',
					render(value, record, index) {
						const date = dateFormat(record.end_time_fact)
						return (
							<HoverSwitch
								hover={
									<DatePicker className='w-[175px]' showTime value={date} />
								}
								defualt={<TimePicker className='w-[175px]' value={date} />}
							/>
						)
					}
				}
			]
		}
	]
}
