import dayjs from 'dayjs'

export const dateFormat = (date?: string | null) => {
	// const minutes = Math.abs(new Date().getTimezoneOffset())
	return date ? dayjs(date) : undefined
	// return date ? dayjs(date).add(minutes, 'minutes') : undefined
}

export const dateFormatWithTimezoneOffset = (date?: string | null) => {
	const minutes = Math.abs(new Date().getTimezoneOffset())
	return date ? dayjs(date).add(minutes, 'minutes') : undefined
}
