import { useGetTableOperationRoutemap } from '../hook/useGetTableOperationRoutemap'
import { useGetRouteOperationAllQuery } from '@/hooks/routeOperation.hook'
import { Table } from 'antd'
import { FC } from 'react'

interface IProps {
	route_map?: number
}

const TableOperationRoutemap: FC<IProps> = ({ route_map }) => {
	const { data, isLoading } = useGetRouteOperationAllQuery({
		route_map
	})
	const columns = useGetTableOperationRoutemap({ data })
	return (
		<div>
			<Table
				className='mt-5'
				loading={isLoading}
				dataSource={data}
				pagination={false}
				columns={columns}
				bordered
			/>
		</div>
	)
}

export default TableOperationRoutemap
