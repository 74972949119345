import TableOperationRoutemap from './components/TableOperationRoutemap'
import ContentContainer from '@/components/Layouts/ContentContainer'
import H1Heading from '@/components/Layouts/Heading/H1Heading'
import MainContainer from '@/components/Layouts/MainContainer'
import { useGetRoutemapQuery } from '@/hooks/routemap.hook'
import { Button, Card } from 'antd'
import { FC } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

const ManualLoaderOpeartions: FC = () => {
	const { route_map_id } = useParams()
	const navigate = useNavigate()
	const { data } = useGetRoutemapQuery(Number(route_map_id))
	return (
		<div className='bg-zinc-100 min-h-screen'>
			<MainContainer>
				<ContentContainer>
					<H1Heading isCard isHelmet>
						{`Операции маршрутной карты ${data?.number || ''} | Ручная загрузка`}
					</H1Heading>
					<Card className='mt-5'>
						<div className='flex gap-5'>
							<Button onClick={() => navigate(-1)} type='primary'>
								Назад
							</Button>
							<NavLink to={'/'}>
								<Button danger type='primary'>
									Выйти
								</Button>
							</NavLink>
						</div>
					</Card>
					<TableOperationRoutemap route_map={Number(route_map_id)} />
				</ContentContainer>
			</MainContainer>
		</div>
	)
}

export default ManualLoaderOpeartions
